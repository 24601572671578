import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../services/Loader";
import {
  placeOrder,
  addAddress,
  calculateDiscountedAmt,
  getAddresses,getCartItems
} from "../../services/APIService";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { loadCart, sTotal } from "../../store/slices/cartSlice";


export default function Checkout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const checkoutData = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [addressData, setAddress] = useState([]);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!checkoutData) {
      navigate("/cart");
    }
    loadAddress();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  const loadAddress = async () => {
    try {
      setIsLoading(true);
      const response = await getAddresses();
      console.log(response);
      setAddress(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  

  const onSubmit = (data) => {
    if (isAddressSelected) {
      console.log("address selected..");
      placedOrderWithExistingAddress(data);
    } else {
      console.log("address not selected..");
      placedOrderWithNewAddress(data);
    }
  };

  const onAddressSelect = (item) => {
    setIsAddressSelected(true);
    setSelectedAddressId(item.addressId);
  };

  const placedOrderWithExistingAddress = async (data) => {
    try {
      setIsLoading(true);
      // const response = await addAddress(data);
      let orderItems = [];
      for (let i = 0; i < checkoutData?.cartData.length; i++) {
        orderItems.push({
          orderDtlId: 0,
          vendorId: checkoutData?.cartData[i].vendorId, //not in cart items
          productId: checkoutData?.cartData[i].productId, //not in cart items
          qty: checkoutData?.cartData[i].qty,
          price: checkoutData?.cartData[i].price,
          variantId: checkoutData?.cartData[i].variantId,
          tax: 0,
          couponName: "",
          discountAmount: checkoutData?.cartData[i].discount,
          shippingCost: 0,
          createdAt: "2024-09-25T08:43:30.995Z",
          updatedAt: "2024-09-25T08:43:30.995Z",
          orderId: 0,
          status: "",
          crud: "string",
          productName: checkoutData?.cartData[i].productName,
          vendorName: "",
          variation: checkoutData?.cartData[i].variation,
          orderDetailStatus: "",
          vendorVariantId: checkoutData?.cartData[i].vendor_variant_id,
        });
      }
      var orderData = {
        orderId: 0,
        userId: 0,
        orderNumber: Date.now().toString(),
        couponName: "string",
        shippingCost: checkoutData.charges,
        paymentType: 1,
        status: "",
        comment: "string",
        createdAt: "2024-09-25T08:43:30.995Z",
        updatedAt: "2024-09-25T08:43:30.995Z",
        shippingAddressId: selectedAddressId,
        billingAddressId: selectedAddressId,
        createdBy: 0,
        updatedBy: 0,
        crud: "string",
        orderDetails: orderItems,
      };
      console.log("OrderData", JSON.stringify(orderData));
      const res = await placeOrder(orderData);
      console.log(res);
      await loadCartItems();
      if (res.orderId) {
        navigate("/order-status");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  
  const loadCartItems = async () => {
    try {
      setIsLoading(true);
      const response = await getCartItems();
      console.log("cart--->",response);
      dispatch(loadCart(response));
      let sub_Total = 0;
      for (let i = 0; i < response.length; i++) {
        sub_Total += calculateDiscountedAmt(response[i]) * response[i].qty;
      }
      dispatch(sTotal(sub_Total));
      setIsLoading(false);
    } catch (error) {
      dispatch(loadCart([]));
      dispatch(sTotal(0));
      //setIsLoading(false);
      console.log(error);
    }
  };
  const addNewAddress=()=>{
    setIsAddressSelected(false);
    setSelectedAddressId(null);
  }

  const placedOrderWithNewAddress = async (data) => {
    try {
      setIsLoading(true);
      const response = await addAddress(data);
      let orderItems = [];
      for (let i = 0; i < checkoutData?.cartData.length; i++) {
        orderItems.push({
          orderDtlId: 0,
          vendorId: checkoutData?.cartData[i].vendorId, //not in cart items
          productId: checkoutData?.cartData[i].productId, //not in cart items
          qty: checkoutData?.cartData[i].qty,
          price: checkoutData?.cartData[i].price,
          variantId: checkoutData?.cartData[i].variantId,
          tax: 0,
          couponName: "",
          discountAmount: checkoutData?.cartData[i].discount,
          shippingCost: 0,
          createdAt: "2024-09-25T08:43:30.995Z",
          updatedAt: "2024-09-25T08:43:30.995Z",
          orderId: 0,
          status: "",
          crud: "string",
          productName: checkoutData?.cartData[i].productName,
          vendorName: "",
          variation: checkoutData?.cartData[i].variation,
          orderDetailStatus: "",
          vendorVariantId: checkoutData?.cartData[i].vendor_variant_id,
        });
      }
      var orderData = {
        orderId: 0,
        userId: 0,
        orderNumber: Date.now().toString(),
        couponName: "string",
        shippingCost: checkoutData.charges,
        paymentType: 1,
        status: "",
        comment: "string",
        createdAt: "2024-09-25T08:43:30.995Z",
        updatedAt: "2024-09-25T08:43:30.995Z",
        shippingAddressId: response.addressId,
        billingAddressId: response.addressId,
        createdBy: 0,
        updatedBy: 0,
        crud: "string",
        orderDetails: orderItems,
      };
      console.log("OrderData", JSON.stringify(orderData));
      const res = await placeOrder(orderData);
      console.log(res);
      await loadCartItems();
      if (res.orderId) {
        navigate("/order-status");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="pt-140">
      {isLoading && <Loader />}

      <div
        className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
        data-bg="img/bg/9.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                <div className="section-title-area ltn__section-title-2">
                  <h1 className="section-title white-color">Checkout</h1>
                </div>
                <div className="ltn__breadcrumb-list">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Checkout</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ltn__checkout-area mb-105">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__checkout-inner">
                  <div className="ltn__checkout-single-content">
                    <h4 className="title-2">Billing Details</h4>
                    <div className="ltn__checkout-single-content-info">
                      <div className="row mb-3">
                        <div className="col-lg-12 col-md-12">
                          <h6>Select Address</h6>
                          {addressData &&
                            addressData.map((item, i) => (
                              <div key={i} className="col-lg-12 col-md-12 mb-3">
                                <div className="input-item">
                                  <input
                                    className="me-2"
                                    type="radio"
                                    name="userAddress"
                                    id={i}
                                    placeholder="Address Type"
                                    // checked={item.isDefault}
                                    onChange={() => onAddressSelect(item)}
                                  />
                                  <label htmlFor={i}>
                                    {" "}
                                    {item.addressType}- {item.streetAddress},{" "}
                                    {item.landmark}, {item.pincode}
                                  </label>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <h6>Add New Address</h6>
                          <div className="row mb-3">
                            <div className="col-md-6">
                              <div className="input-item">
                                <input
                                  type="text"
                                  placeholder="Street address"
                                  {...register("streetAddress", {
                                    // required: "Enter street address",
                                    validate: (value)=> {
                                      if (!isAddressSelected){
                                        if (!value){
                                          return "Enter street";
                                        }
                                      }
                                      // if (!value) return "Enter street";
                                      return true;
                                    },
                                     
                                  })}
                                  onChange={addNewAddress}
                                />
                                {errors?.streetAddress && (
                                  <p className="text-danger">
                                    {errors?.streetAddress.message}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-item">
                                <input
                                  type="text"
                                  placeholder="Landmark"
                                  {...register("landmark", {
                                    //required: "Enter landmark",
                                    validate: (value)=> {
                                      if (!isAddressSelected){
                                        if (!value){
                                          return "Enter landmark";
                                        }
                                      }
                                      // if (!value) return "Enter street";
                                      return true;
                                    },
                                  })}
                                />
                                {errors?.landmark && (
                                  <p className="text-danger">
                                    {errors?.landmark.message}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="input-item">
                            <input
                              type="text"
                              placeholder="Pincode"
                              {...register("pincode", {
                                // required: "Enter pincode",
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: "Enter valid pincode.",
                                },
                                minLength: {
                                  value: 6,
                                  message: "Enter min 6 digits",
                                },
                                maxLength: {
                                  value: 6,
                                  message: "Enter max 6 digits",
                                },
                                validate: (value)=> {
                                  if (!isAddressSelected){
                                    if (!value){
                                      return "Enter pincode";
                                    }
                                  }
                                  // if (!value) return "Enter street";
                                  return true;
                                },
                              })}
                            />
                            {errors?.pincode && (
                              <p className="text-danger">
                                {errors?.pincode.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-3">
                          <div className="input-item">
                            <input
                              type="text"
                              placeholder="Address Type"
                              {...register("addressType")}
                            />
                            {errors?.addressType && (
                              <p className="text-danger">
                                {errors?.addressType.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ltn__checkout-payment-method mt-50">
                  <h4 className="title-2">Payment Method</h4>
                  <div id="checkout_accordion_1">
                    <div className="card">
                      <h5
                        className="ltn__card-title"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-item-2-2"
                        aria-expanded="true"
                        role="button"
                      >
                        Cash on delivery{" "}
                        <img src="img/icons/cash.png" alt="#" />
                      </h5>
                      <div
                        id="faq-item-2-2"
                        className="collapse show"
                        data-parent="#checkout_accordion_1"
                      >
                        <div className="card-body">
                          <p>Pay with cash upon delivery.</p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                    <h5
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-4"
                      aria-expanded="false"
                      role="button"
                    >
                      Online <img src="img/icons/payment-3.png" alt="#" />
                    </h5>
                    <div
                      id="faq-item-2-4"
                      className="collapse"
                      data-parent="#checkout_accordion_1"
                    >
                      <div className="card-body">
                        <p>Pay via UPI/Internet Banking/ Card Payment.</p>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div className="ltn__payment-note mt-30 mb-30">
                    <p>
                      Your personal data will be used to process your order,
                      support your experience throughout this website, and for
                      other purposes described in our privacy policy.
                    </p>
                  </div>
                  <button
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    type="submit"
                  >
                    Place order
                  </button>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="shoping-cart-total mt-50">
                  <h4 className="title-2">Cart Total</h4>
                  <table className="table">
                    <tbody>
                      {checkoutData?.cartData &&
                        checkoutData?.cartData.map((item, i) => (
                          <tr>
                            <td>
                              {item.productName} <strong>× {item.qty}</strong>
                            </td>
                            <td>₹{calculateDiscountedAmt(item) * item.qty}</td>
                          </tr>
                        ))}

                      <tr>
                        <td>Shipping and Handing</td>
                        <td>₹{checkoutData?.charges}</td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Order Total</strong>
                        </td>
                        <td>
                          <strong>₹{checkoutData?.netTotal}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
