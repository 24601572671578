import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getCatelog, calculateDiscountedAmt } from "../../services/APIService";
import Loader from "../../services/Loader";
import { addToCart } from "../../services/APIService";
import { useSelector, useDispatch } from "react-redux";

export default function Products() {
  const navigate = useNavigate();
  const userToken = useSelector((state) => state.user.value);

  const [isLoading, setIsLoading] = useState(false);
  const [productData, setProductData] = useState();
  const [discountedAmt, setDiscountedAmt] = useState(0);

  useEffect(() => {
    loadProducts();
  }, []);

  const loadProducts = async () => {
    try {
      setIsLoading(true);
      const response = await getCatelog();
      setProductData(response.products);
      console.log(response);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const productDetails = (item) => {
    navigate(`/product-details/${item.prodId}/${item.variants[0].vendorVariants[0].vendorId}`);
    window.location.reload();
  };

  const fillCart = async (item) => {
    try {
      if (userToken) {
        setIsLoading(true);
        var data = {
          cartId: 0,
          qty: 1,
          vendorVariantId: item?.variants[0]?.vendorVariants[0]?.idVendorVariant,
          //vendorVariantId: item?.vendorVariant?.idVendorVariant,
          crud: "string",
        };
        const response = await addToCart(data);
        navigate("/cart");
        setIsLoading(false);
      } else {
        navigate("/login");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="pt-140">
      {isLoading && <Loader />}

      <div
        className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image plr--9---"
        data-bg="img/bg/9.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                <div className="section-title-area ltn__section-title-2">
                  <h1 className="section-title white-color">Shopping</h1>
                </div>
                <div className="ltn__breadcrumb-list">
                  <ul>
                    <li>
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li>Shop</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ltn__product-area ltn__product-gutter mb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* <div className="ltn__shop-options">
                        <ul>
                            <li>
                                <div className="ltn__grid-list-tab-menu ">
                                    <div className="nav">
                                        <a className="active show" data-bs-toggle="tab" href="#liton_product_grid"><i className="fas fa-th-large"></i></a>
                                        <a data-bs-toggle="tab" href="#liton_product_list"><i className="fas fa-list"></i></a>
                                    </div>
                                </div>
                            </li>
                            <li>
                               <div className="short-by text-center">
                                    <select className="nice-select">
                                        <option>Default sorting</option>
                                        <option>Sort by popularity</option>
                                        <option>Sort by new arrivals</option>
                                        <option>Sort by price: low to high</option>
                                        <option>Sort by price: high to low</option>
                                    </select>
                                </div> 
                            </li>
                            <li>
                               <div className="showing-product-number text-right text-end">
                                    <span>Showing 9 of 20 results</span>
                                </div> 
                            </li>
                        </ul>
                    </div> */}
              <div className="tab-content">
                <div
                  className="tab-pane fade active show"
                  id="liton_product_grid"
                >
                  <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                    <div className="row">
                      {/* <!-- ltn__product-item --> */}
                      {productData &&
                        productData.map((item, i) => (
                          <div className="col-xl-3 col-lg-4 col-sm-6 col-6">
                            <div className="ltn__product-item ltn__product-item-3 text-center">
                              <div className="product-img">
                                <a
                                  role="button"
                                  onClick={() => productDetails(item)}
                                  href="javascript:void(0)"
                                >
                                  {/* <img src={item?.variants[0]?.images[0]?.image} alt="#" /> */}
                                  <img src={"https://devapi.mvp.atopd.in/Assets/"+item?.images[0]?.image} alt="#" />
                                  {/* <img src="https://devapi.mvp.atopd.in/Assets/1.png" alt="#" /> */}
                                </a>
                                {item.isHot && (
                                  <div className="product-badge">
                                    <ul>
                                      <li className="sale-badge">Hot</li>
                                    </ul>
                                  </div>
                                )}

                                <div className="product-hover-action">
                                  <ul>
                                    <li>
                                      <a
                                        onClick={() => productDetails(item)}
                                        role="button"
                                        title="Quick View"
                                        data-bs-toggle="modal"
                                        data-bs-target="#quick_view_modal"
                                      >
                                        <i className="far fa-eye"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() => fillCart(item)}
                                        href="javascript:void(0);"
                                        title="Add to Cart"
                                        data-bs-toggle="modal"
                                        data-bs-target="#add_to_cart_modal"
                                      >
                                        <i className="fas fa-shopping-cart"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="#"
                                        title="Wishlist"
                                        data-bs-toggle="modal"
                                        data-bs-target="#liton_wishlist_modal"
                                      >
                                        <i className="far fa-heart"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="product-info">
                                <div className="product-ratting">
                                  <ul>
                                    <li>
                                      <a href="#">
                                        <i className="fas fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="fas fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="fas fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="fas fa-star-half-alt"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="far fa-star"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <h2 className="product-title">
                                  <a href="javascript:void(0);" onClick={() => productDetails(item)}>
                                    {item.productName}
                                  </a>
                                </h2>
                                <div className="product-price">
                                  {item?.variants[0].vendorVariants[0] && (
                                    <>
                                      <span>
                                        ₹
                                        {calculateDiscountedAmt(
                                          item?.variants[0].vendorVariants[0]
                                        )}
                                      </span>
                                      <del>₹{item?.variants[0].vendorVariants[0].price}</del>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                      {/* <div className="col-xl-3 col-lg-4 col-sm-6 col-6">
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/2.png" alt="#" />
                            </a>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Vegetables Juices
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$62.00</span>
                              <del>$85.00</del>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="ltn__pagination-area text-center">
                        <div className="ltn__pagination">
                            <ul>
                                <li><a href="#"><i className="fas fa-angle-double-left"></i></a></li>
                                <li><a href="#">1</a></li>
                                <li className="active"><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li><a href="#">...</a></li>
                                <li><a href="#">10</a></li>
                                <li><a href="#"><i className="fas fa-angle-double-right"></i></a></li>
                            </ul>
                        </div>
                    </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
