import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../services/Loader";
import {
  addToCart,
  calculateDiscountedAmt,
  getSingleCatelog,
} from "../../services/APIService";
import { useSelector, useDispatch } from "react-redux";

export default function ProductsDetails() {
  const { pId,vId } = useParams();
  const navigate = useNavigate();
  const userToken = useSelector((state) => state.user.value);
  const location = useLocation();
  // const productDetails = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [productDetails, setProductDetails] = useState(false);
  const [qty, setQty] = useState(1);
  // const [showMsg, setShowMsg] = useState(false);
  // const [msgClass, setMsgClass] = useState("");
  // const [msgText, setMsgText] = useState("");

  useEffect(() => {
    loadOrderData();
  }, []);

  const loadOrderData = async () => {
    try {
      setIsLoading(true);
      const response = await getSingleCatelog(pId,vId);
      setProductDetails(response.products[0]);
      console.log(response.products[0]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const fillCart = async () => {
    try {
      if (userToken) {
        setIsLoading(true);
        var data = {
          cartId: 0,
          qty: qty,
          vendorVariantId: productDetails?.variants[0]?.vendorVariants[0]?.idVendorVariant,
          //  variant_id: productDetails?.vendorVariant?.variantId,
          //vendorVariantId: productDetails?.vendorVariant?.idVendorVariant,
          crud: "string",
        };
        const response = await addToCart(data);
        navigate("/cart");
        setIsLoading(false);
      } else {
        navigate("/login");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="pt-140">
      {isLoading && <Loader />}
      <div
        className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
        data-bg="/img/bg/9.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                <div className="section-title-area ltn__section-title-2">
                  <h1 className="section-title white-color">Product Details</h1>
                </div>
                <div className="ltn__breadcrumb-list">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Product Details</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ltn__shop-details-area pb-85">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="ltn__shop-details-inner mb-60">
                <div className="row">
                  <div className="col-md-6">
                    <div className="ltn__shop-details-img-gallery">
                      <div className="ltn__shop-details-large-img">
                        <div className="single-large-img">
                          <a
                            href="/img/product/1.png"
                            data-rel="lightcase:myCollection"
                          >
                            <img src="/img/product/1.png" alt="Image" />
                          </a>
                        </div>
                        <div className="single-large-img">
                          <a
                            href="/img/product/2.png"
                            data-rel="lightcase:myCollection"
                          >
                            <img src="/img/product/2.png" alt="Image" />
                          </a>
                        </div>
                        <div className="single-large-img">
                          <a
                            href="/img/product/3.png"
                            data-rel="lightcase:myCollection"
                          >
                            <img src="/img/product/3.png" alt="Image" />
                          </a>
                        </div>
                        <div className="single-large-img">
                          <a
                            href="/img/product/4.png"
                            data-rel="lightcase:myCollection"
                          >
                            <img src="/img/product/4.png" alt="Image" />
                          </a>
                        </div>
                        <div className="single-large-img">
                          <a
                            href="/img/product/5.png"
                            data-rel="/lightcase:myCollection"
                          >
                            <img src="/img/product/5.png" alt="Image" />
                          </a>
                        </div>
                        <div className="single-large-img">
                          <a
                            href="/img/product/6.png"
                            data-rel="lightcase:myCollection"
                          >
                            <img src="/img/product/6.png" alt="Image" />
                          </a>
                        </div>
                        <div className="single-large-img">
                          <a
                            href="/img/product/7.png"
                            data-rel="lightcase:myCollection"
                          >
                            <img src="/img/product/7.png" alt="Image" />
                          </a>
                        </div>
                      </div>
                      <div className="ltn__shop-details-small-img slick-arrow-2">
                        <div className="single-small-img">
                          <img src="/img/product/1.png" alt="Image" />
                        </div>
                        <div className="single-small-img">
                          <img src="/img/product/2.png" alt="Image" />
                        </div>
                        <div className="single-small-img">
                          <img src="/img/product/3.png" alt="Image" />
                        </div>
                        <div className="single-small-img">
                          <img src="/img/product/4.png" alt="Image" />
                        </div>
                        <div className="single-small-img">
                          <img src="/img/product/5.png" alt="Image" />
                        </div>
                        <div className="single-small-img">
                          <img src="/img/product/6.png" alt="Image" />
                        </div>
                        <div className="single-small-img">
                          <img src="/img/product/7.png" alt="Image" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="modal-product-info shop-details-info pl-0">
                      <div className="product-ratting">
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fas fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star-half-alt"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="far fa-star"></i>
                            </a>
                          </li>
                          <li className="review-total">
                            {" "}
                            <a href="#"> ( 95 Reviews )</a>
                          </li>
                        </ul>
                      </div>
                      <h3>{productDetails.productName}</h3>
                      {productDetails &&  <div className="product-price">
                        {productDetails?.variants[0].vendorVariants[0] && (
                          <>
                            <span>
                              ₹
                              {calculateDiscountedAmt(
                                productDetails?.variants[0].vendorVariants[0]
                              )}
                            </span>
                            <del>₹{productDetails?.variants[0].vendorVariants[0].price}</del>
                          </>
                        )}
                        {/* <span>${productDetails.flatRate}</span>
                        <del>$65.00</del> */}
                      </div>}
                     
                      <div className="modal-product-meta ltn__product-details-menu-1">
                        <ul>
                          <li>
                            <strong>Categories:</strong>
                            <span>
                              <a href="javascript:void(0)">
                                {productDetails.categoryName}
                              </a>
                              <a href="javascript:void(0)">
                                {productDetails.subCategoryName} {qty}
                              </a>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="ltn__product-details-menu-2">
                        <ul>
  
                          <li>
                            <a
                              // href="javascript:void(0)"
                              className="theme-btn-1 btn btn-effect-1"
                              title="Add to Cart"
                              data-bs-toggle="modal"
                              data-bs-target="#add_to_cart_modal"
                              onClick={fillCart}
                            >
                              <i className="fas fa-shopping-cart"></i>
                              <span>ADD TO CART</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="ltn__product-details-menu-3">
                        <ul>
                          <li>
                            <a
                              href="javascript:void(0)"
                              className=""
                              title="Wishlist"
                              data-bs-toggle="modal"
                              data-bs-target="#liton_wishlist_modal"
                            >
                              <i className="far fa-heart"></i>
                              <span>Add to Wishlist</span>
                            </a>
                          </li>
                          {/* <li>
                            <a
                              href="#"
                              className=""
                              title="Compare"
                              data-bs-toggle="modal"
                              data-bs-target="#quick_view_modal"
                            >
                              <i className="fas fa-exchange-alt"></i>
                              <span>Compare</span>
                            </a>
                          </li> */}
                        </ul>
                      </div>
                      <hr />
                      <div className="ltn__social-media">
                        <ul>
                          <li>Share:</li>
                          <li>
                            <a href="javascript:void(0)" title="Facebook">
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0)" title="Twitter">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0)" title="Linkedin">
                              <i className="fab fa-linkedin"></i>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0)" title="Instagram">
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <hr />
                      <div className="ltn__safe-checkout">
                        <h5>Guaranteed Safe Checkout</h5>
                        <img
                          src="/img/icons/payment-2.png"
                          alt="Payment Image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ltn__shop-details-tab-inner ltn__shop-details-tab-inner-2">
                <div className="ltn__shop-details-tab-menu">
                  <div className="nav">
                    <a
                      className="active show"
                      data-bs-toggle="tab"
                      href="#liton_tab_details_1_1"
                    >
                      Description
                    </a>
                    <a
                      data-bs-toggle="tab"
                      href="#liton_tab_details_1_2"
                      className=""
                    >
                      Reviews
                    </a>
                  </div>
                </div>
                <div className="tab-content">
                    <div
                    className="tab-pane fade active show"
                    id="liton_tab_details_1_1"
                  >
                    <div className="ltn__shop-details-tab-content-inner">
                      <h4 className="title-2">
                        Product Description
                      </h4>
                      <p>
                       {productDetails.description}
                      </p>
                     
                    </div>
                  </div>
                  <div className="tab-pane fade" id="liton_tab_details_1_2">
                    <div className="ltn__shop-details-tab-content-inner">
                      <h4 className="title-2">Customer Reviews</h4>
                      <div className="product-ratting">
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fas fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star-half-alt"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="far fa-star"></i>
                            </a>
                          </li>
                          <li className="review-total">
                            {" "}
                            <a href="#"> ( 95 Reviews )</a>
                          </li>
                        </ul>
                      </div>
                      <hr />
                      <div className="ltn__comment-area mb-30">
                        <div className="ltn__comment-inner">
                          <ul>
                            <li>
                              <div className="ltn__comment-item clearfix">
                                <div className="ltn__commenter-img">
                                  <img
                                    src="img/testimonial/1.jpg"
                                    alt="Image"
                                  />
                                </div>
                                <div className="ltn__commenter-comment">
                                  <h6>
                                    <a href="#">Adam Smit</a>
                                  </h6>
                                  <div className="product-ratting">
                                    <ul>
                                      <li>
                                        <a href="#">
                                          <i className="fas fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i className="fas fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i className="fas fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i className="fas fa-star-half-alt"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i className="far fa-star"></i>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipisicing elit. Doloribus, omnis fugit
                                    corporis iste magnam ratione.
                                  </p>
                                  <span className="ltn__comment-reply-btn">
                                    September 3, 2020
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="ltn__comment-item clearfix">
                                <div className="ltn__commenter-img">
                                  <img
                                    src="img/testimonial/3.jpg"
                                    alt="Image"
                                  />
                                </div>
                                <div className="ltn__commenter-comment">
                                  <h6>
                                    <a href="#">Adam Smit</a>
                                  </h6>
                                  <div className="product-ratting">
                                    <ul>
                                      <li>
                                        <a href="#">
                                          <i className="fas fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i className="fas fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i className="fas fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i className="fas fa-star-half-alt"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i className="far fa-star"></i>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipisicing elit. Doloribus, omnis fugit
                                    corporis iste magnam ratione.
                                  </p>
                                  <span className="ltn__comment-reply-btn">
                                    September 2, 2020
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="ltn__comment-item clearfix">
                                <div className="ltn__commenter-img">
                                  <img
                                    src="img/testimonial/2.jpg"
                                    alt="Image"
                                  />
                                </div>
                                <div className="ltn__commenter-comment">
                                  <h6>
                                    <a href="#">Adam Smit</a>
                                  </h6>
                                  <div className="product-ratting">
                                    <ul>
                                      <li>
                                        <a href="#">
                                          <i className="fas fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i className="fas fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i className="fas fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i className="fas fa-star-half-alt"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i className="far fa-star"></i>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipisicing elit. Doloribus, omnis fugit
                                    corporis iste magnam ratione.
                                  </p>
                                  <span className="ltn__comment-reply-btn">
                                    September 2, 2020
                                  </span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="ltn__comment-reply-area ltn__form-box mb-30">
                        <form action="#">
                          <h4 className="title-2">Add a Review</h4>
                          <div className="mb-30">
                            <div className="add-a-review">
                              <h6>Your Ratings:</h6>
                              <div className="product-ratting">
                                <ul>
                                  <li>
                                    <a href="#">
                                      <i className="fas fa-star"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <i className="fas fa-star"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <i className="fas fa-star"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <i className="fas fa-star-half-alt"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <i className="far fa-star"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="input-item input-item-textarea ltn__custom-icon">
                            <textarea placeholder="Type your comments...."></textarea>
                          </div>
                          <div className="input-item input-item-name ltn__custom-icon">
                            <input
                              type="text"
                              placeholder="Type your name...."
                            />
                          </div>
                          <div className="input-item input-item-email ltn__custom-icon">
                            <input
                              type="email"
                              placeholder="Type your email...."
                            />
                          </div>
                          <div className="input-item input-item-website ltn__custom-icon">
                            <input
                              type="text"
                              name="website"
                              placeholder="Type your website...."
                            />
                          </div>
                          <label className="mb-0">
                            <input type="checkbox" name="agree" /> Save my name,
                            email, and website in this browser for the next time
                            I comment.
                          </label>
                          <div className="btn-wrapper">
                            <button
                              className="btn theme-btn-1 btn-effect-1 text-uppercase"
                              type="submit"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="ltn__product-slider-area ltn__product-gutter pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2">
                <h6 className="section-subtitle ltn__secondary-color">
                  // cars
                </h6>
                <h1 className="section-title">
                  Related Products<span>.</span>
                </h1>
              </div>
            </div>
          </div>
          <div className="row ltn__related-product-slider-one-active slick-arrow-1">
            <div className="col-lg-12">
              <div className="ltn__product-item ltn__product-item-3 text-center">
                <div className="product-img">
                  <a href="product-details.html">
                    <img src="img/product/7.png" alt="#" />
                  </a>
                  <div className="product-badge">
                    <ul>
                      <li className="sale-badge">New</li>
                    </ul>
                  </div>
                  <div className="product-hover-action">
                    <ul>
                      <li>
                        <a
                          href="#"
                          title="Quick View"
                          data-bs-toggle="modal"
                          data-bs-target="#quick_view_modal"
                        >
                          <i className="far fa-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Add to Cart"
                          data-bs-toggle="modal"
                          data-bs-target="#add_to_cart_modal"
                        >
                          <i className="fas fa-shopping-cart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Wishlist"
                          data-bs-toggle="modal"
                          data-bs-target="#liton_wishlist_modal"
                        >
                          <i className="far fa-heart"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="product-info">
                  <div className="product-ratting">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star-half-alt"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="far fa-star"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h2 className="product-title">
                    <a href="product-details.html">Red Hot Tomato</a>
                  </h2>
                  <div className="product-price">
                    <span>$149.00</span>
                    <del>$162.00</del>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__product-item ltn__product-item-3 text-center">
                <div className="product-img">
                  <a href="product-details.html">
                    <img src="img/product/8.png" alt="#" />
                  </a>
                  <div className="product-badge">
                    <ul>
                      <li className="sale-badge">New</li>
                    </ul>
                  </div>
                  <div className="product-hover-action">
                    <ul>
                      <li>
                        <a
                          href="#"
                          title="Quick View"
                          data-bs-toggle="modal"
                          data-bs-target="#quick_view_modal"
                        >
                          <i className="far fa-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Add to Cart"
                          data-bs-toggle="modal"
                          data-bs-target="#add_to_cart_modal"
                        >
                          <i className="fas fa-shopping-cart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Wishlist"
                          data-bs-toggle="modal"
                          data-bs-target="#liton_wishlist_modal"
                        >
                          <i className="far fa-heart"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="product-info">
                  <div className="product-ratting">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star-half-alt"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="far fa-star"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h2 className="product-title">
                    <a href="product-details.html">Vegetables Juices</a>
                  </h2>
                  <div className="product-price">
                    <span>$62.00</span>
                    <del>$85.00</del>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__product-item ltn__product-item-3 text-center">
                <div className="product-img">
                  <a href="product-details.html">
                    <img src="img/product/9.png" alt="#" />
                  </a>
                  <div className="product-badge">
                    <ul>
                      <li className="sale-badge">New</li>
                    </ul>
                  </div>
                  <div className="product-hover-action">
                    <ul>
                      <li>
                        <a
                          href="#"
                          title="Quick View"
                          data-bs-toggle="modal"
                          data-bs-target="#quick_view_modal"
                        >
                          <i className="far fa-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Add to Cart"
                          data-bs-toggle="modal"
                          data-bs-target="#add_to_cart_modal"
                        >
                          <i className="fas fa-shopping-cart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Wishlist"
                          data-bs-toggle="modal"
                          data-bs-target="#liton_wishlist_modal"
                        >
                          <i className="far fa-heart"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="product-info">
                  <div className="product-ratting">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star-half-alt"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="far fa-star"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h2 className="product-title">
                    <a href="product-details.html">Orange Fresh Juice</a>
                  </h2>
                  <div className="product-price">
                    <span>$75.00</span>
                    <del>$92.00</del>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__product-item ltn__product-item-3 text-center">
                <div className="product-img">
                  <a href="product-details.html">
                    <img src="img/product/10.png" alt="#" />
                  </a>
                  <div className="product-badge">
                    <ul>
                      <li className="sale-badge">New</li>
                    </ul>
                  </div>
                  <div className="product-hover-action">
                    <ul>
                      <li>
                        <a
                          href="#"
                          title="Quick View"
                          data-bs-toggle="modal"
                          data-bs-target="#quick_view_modal"
                        >
                          <i className="far fa-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Add to Cart"
                          data-bs-toggle="modal"
                          data-bs-target="#add_to_cart_modal"
                        >
                          <i className="fas fa-shopping-cart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Wishlist"
                          data-bs-toggle="modal"
                          data-bs-target="#liton_wishlist_modal"
                        >
                          <i className="far fa-heart"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="product-info">
                  <div className="product-ratting">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star-half-alt"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="far fa-star"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h2 className="product-title">
                    <a href="product-details.html">Poltry Farm Meat</a>
                  </h2>
                  <div className="product-price">
                    <span>$78.00</span>
                    <del>$85.00</del>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__product-item ltn__product-item-3 text-center">
                <div className="product-img">
                  <a href="product-details.html">
                    <img src="img/product/5.png" alt="#" />
                  </a>
                  <div className="product-badge">
                    <ul>
                      <li className="sale-badge">New</li>
                    </ul>
                  </div>
                  <div className="product-hover-action">
                    <ul>
                      <li>
                        <a
                          href="#"
                          title="Quick View"
                          data-bs-toggle="modal"
                          data-bs-target="#quick_view_modal"
                        >
                          <i className="far fa-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Add to Cart"
                          data-bs-toggle="modal"
                          data-bs-target="#add_to_cart_modal"
                        >
                          <i className="fas fa-shopping-cart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Wishlist"
                          data-bs-toggle="modal"
                          data-bs-target="#liton_wishlist_modal"
                        >
                          <i className="far fa-heart"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="product-info">
                  <div className="product-ratting">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star-half-alt"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="far fa-star"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h2 className="product-title">
                    <a href="product-details.html">Fresh Butter Cake</a>
                  </h2>
                  <div className="product-price">
                    <span>$150.00</span>
                    <del>$180.00</del>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__product-item ltn__product-item-3 text-center">
                <div className="product-img">
                  <a href="product-details.html">
                    <img src="img/product/6.png" alt="#" />
                  </a>
                  <div className="product-badge">
                    <ul>
                      <li className="sale-badge">New</li>
                    </ul>
                  </div>
                  <div className="product-hover-action">
                    <ul>
                      <li>
                        <a
                          href="#"
                          title="Quick View"
                          data-bs-toggle="modal"
                          data-bs-target="#quick_view_modal"
                        >
                          <i className="far fa-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Add to Cart"
                          data-bs-toggle="modal"
                          data-bs-target="#add_to_cart_modal"
                        >
                          <i className="fas fa-shopping-cart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Wishlist"
                          data-bs-toggle="modal"
                          data-bs-target="#liton_wishlist_modal"
                        >
                          <i className="far fa-heart"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="product-info">
                  <div className="product-ratting">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-star-half-alt"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="far fa-star"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h2 className="product-title">
                    <a href="product-details.html">Orange Sliced Mix</a>
                  </h2>
                  <div className="product-price">
                    <span>$150.00</span>
                    <del>$180.00</del>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
